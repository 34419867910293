<template>
  <header class="header-wrapper">
    <div class="header-inner">
      <div class="logo-wrapper">
        <h1>
          <NuxtLink to="/">
            CGBank
          </NuxtLink>
        </h1>
      </div>

      <template v-if="isTagSearchOpen">
        <!-- コンポーネント?? -->
        <div class="search-wrapper">
          <input
            class="input"
            type="text"
            placeholder="「スタイリッシュ」「ミニマル」などキーワードを入れて検索できます"
          >
        </div>
      </template>
    </div>
  </header>
</template>

<script setup lang="ts">
const isTagSearchOpen = ref(false)
// const toggleTagSearch = () => isTagSearchOpen.value = !isTagSearchOpen.value
</script>

<style lang="scss" scoped>
.header-wrapper {
  width: 100%;
  height: 4rem;
  padding: 0.75rem 1.25rem;

  @media (max-width: 768px) {
    padding: 0.5rem 2.5rem;
  }
}

.header-inner {
  height: 100%;
  widows: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .logo-wrapper {
    gap: 1rem;
    display: flex;
    align-items: center;

    > .search {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  > .search-wrapper {
    position: relative;
    flex-grow: 1;

    > .input {
      width: 100%;
      padding: 0.5rem 1rem;
      font-size: $body-s;
      border-radius: 0.25rem;
      background-color: var(--white);
      border: 1px solid var(--secondary);
      letter-spacing: calc($body-s / 10);

      &::placeholder {
        color: var(--secondary);
      }

      &:focus {
        outline: none;
      }

      @media (max-width: 768px) {
        padding: 0.5rem 0.5rem;
      }
    }
  }

  > .button-wrapper {
    gap: 1rem;
    display: flex;
    align-items: center;
  }
}
</style>
